<template>
  <div class="Home">
    <page-header/>

       <section class="uk-section uk-padding-remove">
      <div class="uk-container">

      </div>
      <div class="uk-container uk-margin-top">
        <div class="uk-card uk-card-default uk-padding-remove" style="border-radius: 15px">
          <div class="uk-table uk-table-striped uk-margin-remove uk-text-center uk-table-divider uk-table-responsive">
            <colgroup>
              <col width="3%">
              <col width="6%">
              <col width="10%">
              <col width="25%">
              <col width="8%">
              <col width="8%">
              <col width="6%">
              <col width="7%">
              <col width="6%">
              <col width="6%">
              <col width="6%">
              <col width="10%">
            </colgroup>
            <thead>
            <tr>
              <th style="border-top-left-radius: 15px">No</th>
              <th>{{$t("studentResultView.organiz")}}</th>
              <th >{{$t("studentResultView.date")}}</th>
              <th >{{$t("studentResultView.eName")}}</th>
              <th>{{$t("studentResultView.eTime")}}</th>
              <th>{{$t("studentResultView.eStatus")}}</th>
              <th>{{$t("studentResultView.reTake")}}</th>
              <th>{{$t("studentResultView.eScore")}}</th>
              <th>{{$t("studentResultView.yScore")}}</th>
              <th>{{$t("studentResultView.reTake")}}</th>
              <th>{{$t("studentResultView.variant")}}</th>
              <th style="border-top-right-radius: 15px">...</th>
            </tr>
            </thead>
            <template v-for="(exp,index) in userexams">
              <tr :key="index" class="uk-text-center">
                <td v-bind:rowspan="exp.variants.length + 1">{{index+1}}</td>
                <td v-bind:rowspan="exp.variants.length + 1">
                  <img :src="exp.organizLogo"
                       class="uk-text-center" style="width:39px !important;"/>
                </td>
                <td v-bind:rowspan="exp.variants.length + 1">
                  <span>{{exp.examSdate | moment}}</span>
                  <!--                  <span uk-icon="arrow-down"></span>-->
                  <span class="uk-text-bold" style="color: rgb(1, 176, 255)">~</span>
                  <span>{{exp.examEdate | moment}}</span>
                </td>
                <td v-bind:rowspan="exp.variants.length + 1" class="uk-text-left uk-text-capitalize">
                  <span>{{ exp.examName }}</span>
                  <span class="uk-margin-small-left uk-text-bold"
                        style="color:rgb(1, 176, 255)">
                    ({{ exp.examCode }})</span>
                </td>
                <td v-bind:rowspan="exp.variants.length + 1">
                  {{ exp.examTime }} min
                </td>
                <td v-bind:rowspan="exp.variants.length + 1">
                  <span class="uk-label uk-label-warning uk-text-lowercase"
                        v-if="exp.examStatus == 'ready'">{{ exp.examStatus}}</span>
                  <span class="uk-label uk-label-success uk-text-lowercase"
                        v-else-if="exp.examStatus == 'start'">
                    {{ exp.examStatus}}</span>
                  <span class="uk-label uk-label-danger uk-text-lowercase"
                        v-else>{{ exp.examStatus}}</span>
                </td>
              </tr>
              <tr v-for="data in exp.variantResult" :key="data.id" class="uk-text-center">
                <td>{{ data.totalQuestion }}</td>
                <td>{{ data.totalScore}}</td>
                <td>{{ data.examineeScore }}</td>
                <td>{{ data.reGivedCount }}</td>
                <td>{{ data.variant }}</td>
                <td>
                  <div>
                    <button type="button" class="btn-exam-start">
                      Start
                    </button>
                    <button type="button" class="btn-exam-result">
                      result
                    </button>
                  </div>
                </td>
              </tr>
            </template>
          </div>
        </div>
      </div>
    </section>

    <!-- model -->
    <div id="modalViewAnswer" class="uk-modal-container" uk-modal>
      <div class="uk-modal-dialog uk-modal-body">
        <button class="uk-modal-close-default"  uk-close></button>
        <h3 class="uk-text-center qList">{{currentExamName}}</h3>
        <div class="uk-card uk-card-default uk-margin-top scroll">
          <table class="uk-table uk-table-responsive
                                    uk-table-divider uk-table-striped ">
            <thead class="uk-text-center">
            <tr class="uk-text-center col">
              <th class="uk-table-shrink">No</th>
              <th class="uk-text-center">Question</th>
              <th class="uk-table-shrink">Correct answer</th>
              <th class="uk-table-shrink">Examinee answer</th>
              <!-- <th class="uk-table-shrink">Solved time(second)</th> -->
              <th class="uk-table-shrink">Score</th>
              <th class="uk-table-shrink">True/false</th>
            </tr>
            </thead>
            <tbody class="uk-text-center uk-margin-top">
            <tr class="col" v-for="(item,index) in examineeResultDetail" :key="index">
              <td >{{index+1}}</td>
              <td v-html="item.question"></td>
              <td ><label class="uk-label uk-label-success">{{item.trueAnswer}}</label></td>
              <td ><label class="uk-label uk-label-primary">{{item.checkedAnswers}}</label></td>
              <!-- <td >25 sec</td> -->
              <td >{{item.score}}</td>
              <td >
                <label for class="uk-label uk-label-success" v-if="item.trueAnswer == item.checkedAnswers">O</label>
                <label for class="uk-label uk-label-warning" v-else>X</label>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <p class="uk-text-right">
          <button type="button" class="uk-button uk-button-default uk-modal-close" >Result download</button>
          <button type="button" class="uk-button uk-button-primary uk-modal-close" >okay</button>
        </p>
      </div>
    </div>
    <!-- end model -->

    <section class="uk-section uk-padding-remove ">
      <div class="uk-container">
        <div class="uk-card-footer uk-text-center">
          <div class="uk-flex uk-flex-center">
            <v-pagination
              v-model="currentPage"
              :page-count="pages"
              :classes="uikitClasses"
              :labels="Labels"
              @change="onChange"
            ></v-pagination>
          </div>
        </div>
      </div>
    </section>

    <page-footer/>
  </div>

</template>
<script>
  // import Layout from '../../layouts/Layout'
  import StudentService from '@/services/StudentService'
  import vPagination from "@/components/globals/vue-plain-pagination"
  import moment from 'moment'
  import {mapGetters} from "vuex";
  const PageHeader = () => import("./StudentHeaderNew.vue");
  const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")


  export default {
    name: 'Home',
    components: {

      vPagination,
      // MessageComponent,
      PageHeader,
      PageFooter
    },
    computed: {
      ...mapGetters(["isLoggedIn", "currentUser"])
    },
    created() {
    },
    data() {
      return {
        userexams: [],
        currentPage: 1,
        pages: 0,
        uikitClasses: {
          ul: "uk-pagination",
          li: "",
          liActive: "uk-active",
          liDisable: "uk-disabled",
          button: "page-link"
        },
        Labels: {
          first: "<span uk-icon='chevron-double-left'; ratio = '1.2'></span>",
          prev: "<span uk-pagination-previous></span>",
          next: "<span uk-pagination-next></span>",
          last: "Last"
        },
        currentExamName: '',
      }
    },
    filters: {
      moment: function (date) {
        // return moment(date).format('YYYY.MM.DD')
        // return moment(date).format('YYYY-MM-DD HH:mm:ss')
        return moment(date).format('MM/DD HH:mm')
      }
    },
    methods: {
      async myexamlist(page) {
        try {
          if (page > 0) {
            page = page - 1;
          }
          let item = {
            page: page,
          }
          const response = await StudentService.getExamListStudent(item);
          this.userexams = response.data.content

          if (response.status == 200) {
            this.pages = response.data.totalPages;

            if (!this.userexams.length) {
                this.isLoading = false;
                return;
            }
          }
        } catch (error) {
          console.log(error.response);
          this.error = error.response.data.message;
          this.scrollToTop();
        }
      },
      scrollToTop() {
        window.scrollTo(0, 0);
      },
      onChange: function () {
        console.log(`"currentPage" has been changed`);
        this.myexamlist(this.currentPage);
      },
    }
  }
</script>
<style scoped>
  tr td {
    vertical-align: middle;
  }

  tr th {
    text-align: center;
  }
  .uk-table td {
    padding: 14px 10px !important;
  }
  .btn-exam-start{
    width: 70px;
    height: 30px;
    color: #fff;
    border: 1px solid #c0c0c0;
    border-radius: 3px;
    background: #0d9df9;
    text-transform: capitalize;
  }
  .btn-exam-retake{
    width: 70px;
    height: 30px;
    color: #fff;
    border: 1px solid #c0c0c0;
    border-radius: 3px;
    background: #0d9df9;
    text-transform: capitalize;
  }
  .btn-exam-result{
    width: 70px;
    height: 30px;
    border: 1px solid;
    border-radius: 3px;
    background: #fff;
    text-transform: capitalize;
    margin-top: 5px;
    color: rgb(1, 176, 255);
    border-color: rgb(1, 176, 255);
  }
  .btn-exam-result:hover{
    border-color: rgb(1, 176, 255);
    color: #fff;
    background: rgb(1, 176, 255);
  }
  /*.scroll{*/
  /*  height: 400px;*/
  /*  overflow: auto;*/
  /*}*/
  /*.qList {*/
  /*  height: 20px;*/
  /*  font-size: 1.4em;*/
  /*  font-weight: 800;*/
  /*  color: rgb(1, 176, 255);*/
  /*}*/
  /*.average1{*/
  /*  border-radius: 10px;*/
  /*  background-color: #4FB0C6;*/
  /*  color: #fff;*/
  /*  width: 17.5%;*/
  /*  height: 60px;*/
  /*}*/
  /*.average2{*/
  /*  border-radius: 10px;*/
  /*  background-color: #379392;*/
  /*  color: #fff;*/
  /*  width: 20%;*/
  /*  height: 60px;*/
  /*}*/
  /*.average3{*/
  /*  border-radius: 10px;*/
  /*  background-color: #379392;*/
  /*  color: #fff;*/
  /*  width: 20%;*/
  /*  height: 60px;*/
  /*}*/
  /*.labs{*/
  /*  height: 20px;*/
  /*  font-size: 1.2em;*/
  /*  font-weight: 600;*/
  /*  color: #fff;*/
  /*}*/
  /*.margin-left{*/
  /*  margin-left: 40px;*/
  /*}*/
</style>
